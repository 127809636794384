/**=====================
     51. Search CSS Start
==========================**/
.search-page{
  .product-page-main{
    .tab-content{
      .card-body{
        padding: 0 !important;
      }
    }
  }
  .search-form{
    input{
      background-position:99%;
      &:focus{
        outline: none;
      }
    }
  }
  .nav-link{
    font-weight: 500;
    text-transform: uppercase;
    font-size: 14px !important;
  }
  .borderb-tab-primary {
    margin-bottom: 30px;
  }
  .info-block{
    + .info-block{
      padding-top: 30px;
      border-top: 1px solid $light-color;
      margin-top: 30px;
    }
  }
  .search-links{
    h6{
      margin-bottom: 0;
    }
  }
  p{
    text-transform: lowercase;
    margin-bottom: 0;
    color: $gray-60;
  }
  ul.search-info{
    li{
      display: inline-block;
      font-size: 12px;
      line-height: 1;
      color: #586082;
      + li{
        border-left: 1px solid lighten($dark-color , 50%);
        padding-left: 8px;
        margin-left: 8px;
      }
      i{
        color: $warning-color;
      }
    }
  }
  #video-links{
    .embed-responsive
    + .embed-responsive{
      margin-top: 30px;
    }
  }
}
/**=====================
    51. Search CSS Ends
==========================**/