/**=====================
    57. breadcrumb CSS Start
==========================**/
.breadcrumb-colored{
	li{
		a{
			color: $breadcrumb-ancher-color;
		}
		&.active{
			color: $breadcrumb-ancher-color;
			opacity:0.7;
		}
	}
	.breadcrumb-item{
		&:before{
			color: $breadcrumb-ancher-color;
		}
	}
	.breadcrumb-item.txt-dark{
		&:before{
			color: $breadcrumb-ancher-dark-color;
		}
	}
}
.breadcrumb{
    padding: 0.75rem 1rem;
}
nav.breadcrumb{
    background-color:rgba($primary-color , 0.1);
}
.breadcrumb-item{
	&.active{
		text-transform: capitalize;
	}
}
/**=====================
    57. breadcrumb CSS ends
==========================**/
