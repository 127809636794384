/**=====================
     54. Tour  CSS Start
==========================**/
.kVEayH {
  background: $primary-color  !important;
  height: 30px !important;
  line-height: 2.2 !important;
}
.sc-htpNat {
  &:focus {
    outline: none !important;
  }
}
.eTpeTG {
  top: 18px !important;
  right: 18px !important;
}
.reactour__dot {
  &.reactour__dot--is-active {
    color: $primary-color  !important;
    background:  $primary-color  !important;
  }
}
/**=====================
    54. Tour  CSS Ends
==========================**/