/**=====================
     76. internationalization css start
==========================**/
.hdg_main{
  position: relative;
}
.main {
  .langChoice{
    position: absolute;
    right: 30px;
    top: 20px;
    padding: 10px 12px;
  }
}
/**=====================
     76. internationalization CSS end
==========================**/